




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    OLogin: () => import('@/components/organisms/o-login.vue')
  }
})
